import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
    meta: { authorization: false, admin: false },
  },
  {
    path: "/search",
    name: "search",
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/Search.vue"),
    meta: { authorization: false, admin: false },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/page/:id",
    name: "view",
    // route level code-splitting
    // this generates a separate chunk (view.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "view" */ "../views/Admin/Pages/ViewPage.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Auth/Login.vue"),
    meta: { authorization: false },
  },
  {
    path: "/change-password/:token",
    name: "ChangePassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ChangePassword.vue"
      ),
    meta: { authorization: false },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ "../views/Auth/ForgotPassword.vue"
      ),
    meta: { authorization: false },
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "../views/Auth/Register/index.vue"
      ),
    meta: { authorization: false },
  },
  {
    path: "/menus",
    name: "menus",
    component: () =>
      import(/* webpackChunkName: "menus" */ "../views/Admin/Menus.vue"),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/stats",
    name: "stats",
    component: () =>
      import(/* webpackChunkName: "Stats" */ "../views/Admin/Stats.vue"),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/sales",
    name: "Sales",
    component: () =>
      import(
        /* webpackChunkName: "Sales" */ "../views/Admin/Sales/SalesView.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/sales/create",
    name: "createSale",
    component: () =>
      import(
        /* webpackChunkName: "createSale" */ "../views/Admin/Sales/CreateSale.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: () =>
      import(/* webpackChunkName: "users" */ "../views/Admin/Users/Users.vue"),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/transfers",
    name: "TransfersView",
    component: () =>
      import(
        /* webpackChunkName: "TransfersView" */ "../views/Admin/Transfers/TransfersView.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/transfers/create",
    name: "CreateTransfer",
    component: () =>
      import(
        /* webpackChunkName: "CreateTransfer" */ "../views/Admin/Transfers/CreateTransfer.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/transfers/edit/:id",
    name: "EditTransfer",
    component: () =>
      import(
        /* webpackChunkName: "EditTransfer" */ "../views/Admin/Transfers/ViewTransfer.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/transfers/view/:id",
    name: "ViewTransfer",
    component: () =>
      import(
        /* webpackChunkName: "ViewTransfer" */ "../views/Admin/Transfers/ViewTransfer.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/store",
    name: "Store",
    component: () =>
      import(/* webpackChunkName: "Store" */ "../views/Employee/Store.vue"),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/my-store/transfers",
    name: "StoreTransfers",
    component: () =>
      import(
        /* webpackChunkName: "StoreTransfer" */ "../views/Employee/StoreTransfer.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/my-store/transfers/create",
    name: "StoreTransfer",
    component: () =>
      import(
        /* webpackChunkName: "StoreTransfer" */ "../views/Employee/CreateTransfer.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/stores",
    name: "Stores",
    component: () =>
      import(
        /* webpackChunkName: "Stores" */ "../views/Admin/Stores/Stores.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/stores/:id",
    name: "viewStore",
    component: () =>
      import(
        /* webpackChunkName: "viewStore" */ "../views/Admin/Stores/ViewStore.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/stores/edit/:id",
    name: "editStore",
    component: () =>
      import(
        /* webpackChunkName: "editStore" */ "../views/Admin/Stores/EditStore.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(
        /* webpackChunkName: "products" */ "../views/Admin/Products/Products.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/products/create",
    name: "CreateProduct",
    component: () =>
      import(
        /* webpackChunkName: "CreateProduct" */ "../views/Admin/Products/CreateProduct.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/products/:id",
    name: "ViewProduct",
    component: () =>
      import(
        /* webpackChunkName: "ViewProduct" */ "../views/Admin/Products/ViewProduct.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
    props: true,
  },
  {
    path: "/products/edit/:id",
    name: "EditProduct",
    component: () =>
      import(
        /* webpackChunkName: "EditProduct" */ "../views/Admin/Products/EditProduct.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
    props: true,
  },
  {
    path: "/customers",
    name: "customers",
    component: () =>
      import(
        /* webpackChunkName: "customers" */ "../views/Admin/Customers/ViewCustomers.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: () =>
      import(
        /* webpackChunkName: "orders" */ "../views/Admin/Orders/Orders.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/orders/create",
    name: "CreateOrder",
    component: () =>
      import(
        /* webpackChunkName: "CreateOrder" */ "../views/Admin/Orders/CreateOrder.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/orders/:id",
    name: "ViewOrder",
    component: () =>
      import(
        /* webpackChunkName: "ViewOrder" */ "../views/Admin/Orders/ViewOrder.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/info",
    name: "MyInfo",
    component: () =>
      import(/* webpackChunkName: "MyInfo" */ "../views/User/MyInfo.vue"),
    meta: {
      authorization: true,
      admin: false,
    },
  },
  {
    path: "/my-orders",
    name: "MyOrders",
    component: () =>
      import(/* webpackChunkName: "MyOrders" */ "../views/User/MyOrders.vue"),
    meta: {
      authorization: true,
      admin: false,
    },
  },
  {
    path: "/my-orders/:id",
    name: "ViewMyOrder",
    component: () =>
      import(
        /* webpackChunkName: "ViewMyOrder" */ "../views/Admin/Orders/ViewOrder.vue"
      ),
    meta: {
      authorization: true,
      admin: false,
    },
  },
  {
    path: "/my-orders/bill/:uid",
    name: "ViewMyBill",
    component: () =>
      import(
        /* webpackChunkName: "ViewMyBill" */ "../views/Admin/Bills/ViewBill.vue"
      ),
    meta: {
      authorization: true,
      admin: false,
    },
  },
  {
    path: "/bills",
    name: "bills",
    component: () =>
      import(
        /* webpackChunkName: "billings" */ "../views/Admin/Bills/Bills.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/bills/view/:uid",
    name: "viewBill",
    component: () =>
      import(
        /* webpackChunkName: "billings" */ "../views/Admin/Bills/ViewBill.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/bills/create",
    name: "createBill",
    component: () =>
      import(
        /* webpackChunkName: "createBill" */ "../views/Admin/Bills/CreateBill.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/library",
    name: "library",
    component: () =>
      import(
        /* webpackChunkName: "library" */ "../views/Admin/Library/Library.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/quotes",
    name: "quotes",
    component: () =>
      import(
        /* webpackChunkName: "quotes" */ "../views/Admin/Quotes/Quotes.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/quotes/create",
    name: "QuoteCreate",
    component: () =>
      import(
        /* webpackChunkName: "QuoteCreate" */ "../views/Admin/Quotes/QuoteCreate.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/quotes/:id",
    name: "ViewQuote",
    component: () =>
      import(
        /* webpackChunkName: "ViewQuote" */ "../views/Admin/Quotes/ViewQuote.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/banners",
    name: "BannersView",
    component: () =>
      import(
        /* webpackChunkName: "BannersView" */ "../views/Admin/Banners/BannersView.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/pages",
    name: "PagesView",
    component: () =>
      import(
        /* webpackChunkName: "PagesView" */ "../views/Admin/Pages/AllPages.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/pages/create",
    name: "CreatePage",
    component: () =>
      import(
        /* webpackChunkName: "CreatePage" */ "../views/Admin/Pages/CreatePage.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/pages/:id",
    name: "ViewPage",
    component: () =>
      import(
        /* webpackChunkName: "ViewPage" */ "../views/Admin/Pages/ViewPage.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },
  {
    path: "/pages/edit/:id",
    name: "editPage",
    component: () =>
      import(
        /* webpackChunkName: "editPage" */ "../views/Admin/Pages/CreatePage.vue"
      ),
    meta: {
      authorization: true,
      admin: true,
    },
  },

  {
    path: "/category/:category",
    name: "searchByCategory",
    component: () =>
      import(
        /* webpackChunkName: "searchByCategory" */ "../views/ProductsByCategory.vue"
      ),
    meta: {
      authorization: false,
      admin: false,
    },
    props: true,
  },
  {
    path: "/categories/:category/:subcategory",
    name: "searchByCategories",
    component: () =>
      import(
        /* webpackChunkName: "searchByCategories" */ "../views/SearchByCategories.vue"
      ),
    meta: {
      authorization: false,
      admin: false,
    },
    props: true,
  },
  {
    path: "/:category/:subcategory/:title/:id",
    name: "product",
    component: () =>
      import(/* webpackChunkName: "product" */ "../views/Product.vue"),
    meta: {
      authorization: false,
      admin: false,
    },
    props: true,
  },
  {
    path: "/:title/:id",
    name: "viewProduct",
    component: () =>
      import(/* webpackChunkName: "viewProduct" */ "../views/Product.vue"),
    meta: {
      authorization: false,
      admin: false,
    },
    props: true,
  },
  {
    path: "/carrito",
    name: "carrito",
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/Carrito/Carrito.vue"),
    meta: {
      authorization: true,
      admin: false,
    },
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Success.vue"),
    meta: {
      authorization: true,
      admin: false,
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
    meta: {
      authorization: false,
      admin: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const authorization = to.matched.some((record) => record.meta.authorization);
  const admin = to.matched.some((record) => record.meta.admin);
  const isLogged = store.state.auth.isLogged;
  const role = store.state.auth.role;

  try {
    if (role === "admin" || role === "employee") {
      if (!admin && to.path !== "/logout") {
        next("/stats");
      }
      if (!authorization && isLogged && to.path === "/login") {
        next("/stats");
      } else if (authorization && !isLogged) {
        next("/login");
      } else {
        next();
      }
    } else if (role !== "admin" || role !== "employee") {
      if (admin) {
        next("/");
      }
      if (isLogged && to.path === "/login") {
        next("/");
      } else if (authorization && !isLogged && to.path != "/carrito") {
        next("/login");
      } else {
        next();
      }
    } else {
      if (!authorization && isLogged && to.path === "/login" && admin) {
        next("/");
      }
    }
  } catch (error) {
    if (!authorization && isLogged && to.path === "/login") {
      next("/");
    } else if (authorization && !isLogged && to.path != "/carrito") {
      next("/login");
    } else {
      next();
    }
  }
});

export default router;
