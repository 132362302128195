<template>
  <v-footer
    v-if="role != 'admin' && role != 'employee' && $route.name != 'carrito'"
    class="mt-1"
    color="white"
  >
    <v-row justify="center">
      <v-col cols="12" sm="12" md="8" class="pl-8 mt-8">
        <h3 class="text-uppercase">información de la tienda</h3>
        <p class="text_info mb-1">ControlRed</p>
        <p class="text_info mb-1">Blvd Oscar Flores Sanchez #10225</p>
        <p class="text_info mb-1">C. P. 32695 KM. 20</p>
        <p class="text_info mb-1">Chihuahua, México</p>
        <p class="text_info mb-1">Llámenos: 656 2 66 8000</p>
        <p class="text_info mb-1">Envíenos un mensaje de correo electrónico:</p>
        <a
          href="mailto:sistemas@controlred.com.mx"
          class="mail_to primary--text"
        >
          sistemas@controlred.com.mx
        </a>
      </v-col>
      <v-col cols="12" sm="12" md="4" class="pl-8 mt-8">
        <a
          href="http://server.controlred.com.mx/ControlOC/"
          style="text-decoration: none;"
        >
          <v-icon color="success" class="mr-3"
            >mdi-book-open-variant-outline</v-icon
          >
          OC CONTROLRED
        </a>
        <div v-for="(page, i) in pages" :key="i">
          <router-link
            class="text-decoration-none"
            v-if="page.status"
            :to="`/page/${page.title}`"
          >
            {{ page.title }}
          </router-link>
        </div>
      </v-col>
      <v-col cols="12" class="py-4 text-center primary--text">
        {{ new Date().getFullYear() }} <strong>Control red</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  computed: {
    ...mapState('auth', ['user', 'isLogged', 'role']),
    ...mapState('Pages', ['pages'])
  },

  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions('Pages', ['getPages']),

    initialize() {
      this.getPages(`?limit=20&status=true`);
    }
  }
};
</script>

<style scoped>
.text_info {
  font-size: 17px;
  color: #8c8c8c;
}
.mail_to {
  text-decoration: none;
  font-size: 17px;
}
</style>
