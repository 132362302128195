<template>
  <div>
    <div v-for="(category, i) in categories" :key="i">
      <a class="black--text" @click="navigate(category)">
        {{ category.nombre }}
      </a>
    </div>
    <!-- <v-row>
      <v-col
        class="col-12 col-sm-12 col-md-12"
        v-for="(category, i) in categories"
        :key="i"
      >
        <p class="ma-0">{{ category.nombre }}</p>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    category: Object,
  },

  data: () => ({
    categories: null,
  }),

  created() {
    if (this.id) {
      this.getSubCategories();
    }
  },

  methods: {
    async getSubCategories() {
      await this.$http
        .get(`/categories/?level=${3}&categoryId=${this.id}`)
        .then((result) => {
          const categories = result.data;
          this.categories = categories;
          // categories.forEach((item) => console.log(item.origen));
        })
        .catch((err) => {
          console.log(err);
        });
    },

    navigate(item) {
      const { origen } = item;
      let category = origen.find((cat) => cat.nivel == 2);
      let subcategory = item.nombre.replace(/ /g, "_").replace(/\//g, "&");
      this.$emit(
        "action",
        category.nombre.replace(/ /g, "_").replace(/\//g, "&"),
        subcategory
      );
    },
  },
};
</script>

<style></style>
